<template>
    <v-row no-gutters class="secondary" justify="center" style="height: 100vh;" align="center" align-content-center>
		<v-col cols="12" md="6" lg="4">
            <v-card>
                <v-card-text class="pb-1 secondary--text">
                    <template v-if="errors">
                        <v-alert type="error">
                            {{ errors }}
                        </v-alert>
                        <p class="text-center mb-3 headline">Please try again!</p>
                        <p class="subheading text-center">Confirm that you clicked or copied the correct link from your verification email.</p>
                    </template>
                    <template v-else>
                        <p class="text-center mb-3 headline">{{ (submitting) ? 'Verifying your email address...' : 'Your Email has been Verified!' }}</p>
                        <p v-if="!submitting" class="subheading text-center">One moment while we redirect you...</p>
                    </template>
                </v-card-text>
            </v-card>
		</v-col>
    </v-row>
</template>
<script>

import { mapState, mapMutations } from 'vuex'

export default {

    name: 'EmailConfirm',

    computed: {
        ...mapState(['user'])
    },

    data: () => ({
        errors: null,
        email: null,
        prevemail: null,
        submitting: true,
        token: null,
    }),

    methods: {
        ...mapMutations(['setUser']),
        initData(){
            let params = this.getQueryParams();
            this.token = params.token;
            this.email = decodeURIComponent((params.e + '').replace(/\+/g, '%20'));
            this.prevemail = decodeURIComponent((params.pe + '').replace(/\+/g, '%20'));
            if(!this.token || !this.email || (!this.user && !this.prevemail)){
                this.errors = "Invalid token provided. Please check the link from your email.";
            }else{
                this.submit();
            }
        },
        submit(){ 
            this.submitting = true;
            this.errors = null;
            let request = {
                endpoint: '/auth/emailchange', 
                method: 'post',
                data: {
                    email: this.email,
                    token: this.token,
                    prevemail: this.prevemail
                }
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.setUser(res.data);
                    this.$eventHub.$emit('refreshUser');
                    this.$emit('verified');
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your email address has been updated!'
                    });
                    setTimeout(() => {
                        this.$router.push('/app')
                    }, 1000);
                }else{
                    this.errors = "Invalid token provided. Please check the link from your email.";
                }
            }).catch(()=>{
                    this.errors = "Invalid token provided. Please check the link from your email.";
            }).finally(()=>{
                this.submitting = false;
            });
        },
        getQueryParams() {
            var p={};
            location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){p[k]=v})
            return p;
        }
    },
    mounted(){
        this.initData();
    }
}
</script>